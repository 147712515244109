<template>
  <div class="meeting_tip_container global_container_center">
      <div class="meeting_tip_main">
          <div class="meeting_tip_title global_container_center">
            <div class="meeting_tip_bg global_container_center">
              {{config.title}}
            </div>
          </div>
          <div class="meeting_tip_content global_container_center">
              <slot></slot>
          </div>
          <div class="meeting_tip_button_group global_container_center">
              <div class="meeting_tip_button_cancel meeting_tip_button_block global_container_center "  v-for="(item,index) in config.button"  @click="doSelect(index)">
                  <custom-button size="small" round>{{ item }}</custom-button>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import CustomButton from '@/components/unit/CustomButton'
export default {
  name:"meeting_tip",
  props:{
      cfg:{
          type:Object,
          default:()=>{
              return {}
          }
      }
  },
  components:{
    CustomButton
  },
  data(){
      return {
          
          config:{
              title:"添加常用语",
              button:["取消","确认"]
          }
      }
  },
  created(){
      this.config = {
          ...this.config,...this.cfg
      }
  },
  methods:{
    doSelect(index){
      this.$emit("doSelect",index)
    }
  }
}
</script>
<style >
  .meeting_tip_container{
      position: fixed;
      left:0;
      top:0;
      width:100%;
      height:100%;
      background:rgba(30,30,30,0.2);
      z-index:8999;
  }
  .meeting_tip_main{
      background:#fff;
      border-radius:1vw;
      min-width:80%;
      max-width:90%;
      padding-bottom:4vw;
  }
  .meeting_tip_title{
      height:8vw;
      font-size:3.6vw;
  }
  .meeting_tip_bg{
    height:100%;
    width:32vw;
    background: url("../../assets/images/tip-bg.png");
    background-size: 100% 100%;
    color:#fff;
  }
  .meeting_tip_content{
      height:auto;
      min-height:20vw;
      display: flex;
      align-items: center;
      width:100%;
  }
  .meeting_tip_button_group{
      height:8vw;
      display: flex;
      font-size:3.6vw;
  }
  .meeting_tip_button_block{
      height:100%;
      width:100%;
      flex:1 0 0;
  }
  .meeting_tip_button_cancel{
  }
  .meeting_tip_button_block .customButton_container{
    width:auto;
    min-width: 18vw;
    height:6vw !important;
    font-size:3.6vw;
  }
  .meeting_tip_button{
    margin-right:4vw;
  }
</style>