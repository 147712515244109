<template>
    <div class="h5-player_container h5-player_container_h5">
      <video
        class="meeting_head_video_recorder_preview"
        :src="url"
        ref="player"
        controlslist="nodownload"
        preload="auto"
        playsinline
        muted
        autoplay
        controls
        webkit-playsinline
      ></video>
      <div class="h5-player-cover" v-if="showCover"></div>
    </div>
</template>
<script>
export default {
  name:'h5-player',
  data:()=>{
    return {
      url:'',
      muted:true,
      showCover:true,
    }
  },
  mounted(){

  },
  methods:{
    play(status=false){
      this.setMuted(status)
      this.$refs.player.play();
    },
    setCoverStatus(status){
      this.showCover = status
    },
    pause(){
      this.$refs.player.pause();
    },
    toggle(){
      if(this.$refs.player.paused){
        this.play();
      }else{
        this.pause();
      }
    },
    fullScreen(){
      var element = this.$refs.player;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      }else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      }
    },
    setMuted(status){
      this.$refs.player.muted = status;
      document.getElementsByTagName("video")[0].muted = true
    },
    switch(url){
      this.url = url;
      this.$refs.player.srcObject = url
      this.$refs.player.load();
      setTimeout(()=>{
        this.$nextTick(()=>{
          this.$refs.player.muted = true
          document.getElementsByTagName("video")[0].muted = true
        })
      })
    },
    loaded(){
      this.$refs.player.play()
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.h5-player_container,.h5-player_video{
  width:100%;
  height:100vh;
  position:relative;
  overflow:hidden
}
.h5-player_container video{
  /* height:53.33vw !important; */
  width:100vw;
  height:auto;
  position: absolute;
  top:0
}
.h5-player_container video::-webkit-media-controls-timeline {
    display: none !important;
}
/* 全屏按钮 */
.h5-player_container video::-webkit-media-controls-fullscreen-button {
    display:block !important;
    content: "\e719";
}
/* 音量按钮 */
.h5-player_container video::-webkit-media-controls-mute-button {
    display: none!important;            
}

.meeting_head_video_recorder_preview{
  filter:brightness(101%) contrast(84%) saturate(113%);
  -webkit-filter:brightness(101%) contrast(84%) saturate(113%);
  transform: rotateY(180deg);
}
.h5-player_container_h5 video::-webkit-media-controls{
 transform: rotateY(180deg) !important;
 -webkit-transform: rotateY(180deg) !important;
}
/* .h5-player_container video::-webkit-media-controls-panel {
  background:transparent;
}
video::-webkit-media-controls-panel:first-child{
  background:red;
} */
</style>