import tips from '@/common/tips'
import axios from '@/common/axios'
import urls from '@/common/urls'
const globalSize = 200;
const recorder = {
  stream:null,
  chunks:[],
  recorder:null,
  callback:null,
  meetId:0,
  page:0,
  isCanRecorder:false,
  voiceLength:0,
  isAutoStart:false,
  camera:{
    audio: {
      noiseSuppression: true, // 降噪
      echoCancellation: true // 回音消除
    },
    video : {
      height:globalSize,
      width:globalSize,
      facingMode: "user" // 前置摄像头
    }
  },
  sourceType:{type:"video/webm"},
  initRecorder: async () => {
    // 标清 < 0.8 高清 > 0.8 超清>1.5 蓝光 2.5M
    recorder.recorder = new MediaRecorder(recorder.stream,{
      mimeType: 'video/webm',
      audioBitsPerSecond : 128000,
      videoBitsPerSecond : 800000,
  });
    recorder.recorder.ondataavailable = async (e) => {
      recorder.chunks.push(e.data);
    }
  },
  startRecord: async (meetId= 0) => {
    if(meetId){
      recorder.meetId =  meetId
    }
    recorder.chunks = []
    return new Promise((resolve)=>{
      if(!recorder.isCanRecorder){
        resolve({status:500,data:{}})
        return;
      }
      if(!recorder.recorder){
        setTimeout(()=>{
          recorder.initRecorder()
          recorder.recorder &&  recorder.recorder.start()
          resolve({status:200,data:{}})
        },500)
        return;
      }
      if(recorder.recorder.state == "recording"){
        recorder.recorder.onstop = async ()=>{
          recorder.chunks = []
          recorder.recorder.start()
          resolve({status:200,data:{}})
        }
        recorder.recorder.stop()
        return;
      }
      recorder.recorder.start()
      resolve({status:200,data:{}})
    })
  },
  stopRecord: async (voiceLength) => {
    recorder.voiceLength = voiceLength
    recorder.callback = () => {}
    return new Promise((resolve)=>{
      // 录制结束执行
      recorder.recorder.onstop = async (e)=>{
        let data = await recorder.uploadVoice()
        console.log(data)
        if(!data){
          resolve({status:500,data:{}})
        }else{
          resolve({status:200,data:{
            url:data
          }})
        }
      }
      try{
        if(recorder.recorder.state == "recording"){
          recorder.recorder.stop()
        }else{
          recorder.recorder.onstop = () => {}
          recorder.recorder.stop()
          resolve({status:500,data:{}})
        }
      }catch(err)
      {
        resolve({status:500,data:{}})
      }
    })
  },
  getVideoName(){
    let fileName = `meet-video-${recorder.meetId}-${Date.now()}`
    if(!recorder.meetId){
      fileName =  `meet-video-${Math.random()}-${Date.now()}`
    }
    return `${fileName}`
  },
  async post(){
    return new Promise(resolve=>{
      setTimeout(()=>{
        let blob = new Blob(recorder.chunks,recorder.sourceType);
        let data = new FormData()
        console.log(recorder.chunks)
        data.append("file",blob)
        data.append("name",recorder.getVideoName())
        data.append("mid",recorder.meetId)
        axios.post(urls.uploads.video,data).then((res)=>{
          resolve(res.url)
        }).catch(err=>{
          resolve("")
        })
      },500)
    })
  },
  uploadVoice: async () => {
    return new Promise(async (resolve)=>{
      try{
        // if(!recorder.chunks[0] || recorder.chunks[0].size < 5*1000){
          console.log(recorder.chunks)
        if(!recorder.chunks[0]){
          resolve("")
          return
        }
      }catch(err){
        resolve("")
        return;
      }
      let url = await recorder.post()
      console.log(url)
      recorder.chunks = []
      resolve(url)
    })
  },
  playVoice(url){
  },
  stopVoice(url){
  },

  mediaSuccess: async (stream) => {
    recorder.stream = stream;
    recorder.initRecorder()
  },
  mediaError: async (error) => {
    let content = `访问用户媒体设备失败${error.name}, ${error.message}`
    tips.error({text:content})
  },
  init: async (callback) => {
    let mediaSuccess = (stream)=>{
      const track = stream.getVideoTracks()[0];
      track.applyConstraints({width:{min:globalSize,max:globalSize},height:{min:globalSize,max:globalSize}}).then(()=>{
        recorder.mediaSuccess(stream)
        recorder.isCanRecorder = true
        callback(stream)
      })
    }
    let mediaError = (error)=>{
      console.log(error)
      recorder.mediaError(error)
      callback("")
    }
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
      navigator.mediaDevices.getUserMedia(recorder.camera).then(mediaSuccess).catch(mediaError);
      return;
    }
    let execFun = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
    if(execFun){
      execFun(recorder.camera, mediaSuccess, mediaError)
    }else{
      // tips.error({text:'当前浏览器不支持访问用户媒体'});
      mediaError("当前浏览器不支持访问用户媒体")
    }
  }
}

export default recorder
