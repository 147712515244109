<template>
  <div class="live-container" id="live" @click="closeQuestion" :style="getLiveStyle()">
    <div class="live-video">
      <!-- <PhasePlayer class="palyer-tag-1" v-if="meetingInfo.phase < 2 || showPlayer"  ref='player'  :isShowCover="true" @loaded="loaded" @playEnd="playEnd" :isShowButton="false"></PhasePlayer> -->
      <MyPlayer  ref='player'
        :phase="this.meetingInfo.phase"
        :chatList="this.meetingInfo.chat_info"
        :isShowCover="true" @loaded="loaded" @playEnd="playEnd" :isShowButton="false" :isLive="getIsLive"></MyPlayer>
    </div>
    <div class="live-content">
      <MeetingInfoItem :meetingInfo="meetingInfo" ref="meetingInfo"></MeetingInfoItem>
      <Banner :meetingInfo="meetingInfo" @changeBanner="changeBanner"></Banner>
      <div class="live-chat-block">
        <component ref="coursePage"  @sendMessage="sendChat" v-if="components" v-bind:is="components" :isLive="getIsLive" :meetingInfo="meetingInfo"></component>
      </div>
    </div>
    <div class="live-voice-block  global_container_center" v-if="!$tools.isGuest(meetingInfo) && meetingInfo.phase < 4 && meetingInfo.phase != 2"  >
      <div class="live-voice-item"  @click="toggleRecored" :class="{'live-voice-play-end':[3].includes(meetingInfo.phase)}">
        <div class="live-voice-tip" v-if="liveTime.maxCount-liveTime.currTime <= 10 ">
          <div class="live-voice-tip-text">{{liveTime.maxCount-liveTime.currTime}}秒后停止发言</div>
        </div>
        <div class="live-voice-bg global_container_position" :class="{'live-voice-active':isRecord}" ></div>
        <div class="global_container_position live-voice-img global_container_center"   alt="">
          <img class="live-voice-img-pause"  v-if="!isRecord" :src="require('@/assets/images/live/voice.png')" />
          <img class="live-voice-img-record" v-else :src="require('@/assets/images/live/voice-icon.png')" />
          <div class="global_container_mask"></div>
        </div>
      </div>
    </div>
    <MeetingTip :cfg="meetingIsNoStartMsg" @doSelect="quitMeet" v-if="isComplete&&!getIsLive" >
      <div class="live-meeting-tip">
        <div class="live-meeting-li">
          {{ getMeetStatusTips()}}
        </div>
      </div>
    </MeetingTip>
    <MeetingTip :cfg="meetingMsg" @doSelect="doSelect"  v-if="getIsLive&&showMeetingTip">
      <div class="live-meeting-tip">
        <div class="live-meeting-li" :style="meetingMsg.style ? meetingMsg.style[index] : ''" :key="item" v-for="(item,index) in meetingMsg.content">
          {{ item }}
        </div>
      </div>
    </MeetingTip>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import MeetingInfoItem from '@/components/page/live/components/meetingInfo.vue'
import Banner from '@/components/page/live/components/banner.vue'
import MyPlayer from '@/components/player/Player'
import H5Player from '@/components/player/H5Player'
import PhasePlayer from '@/components/player/PhasePlayer'
import MeetingTip from '@/components/unit/MeetingTip'
import recorder from '@/common/recorder';
import websocket from '@/common/websockets';
import weixin from "@/common/weixin";
export default {
  name:"live",
  data:()=>{
    return {
      meetingInfo:{
        // 0 提问 1 会议介绍
        is_test:0,// 是否测试
        tab:0,// 菜单
        role:0,// 用户角色
        meeting_number:0, 
        isPlaying:false, // 是否可以播放
        phase:0, // 阶段
        meeting_info:{},
        chat_info:[],
        class_info:{},
        control:[],
        openid:"",
        count:0,
      },
      meetingIsNoStartMsg:{
        title: "提示",
        content: [],
        style: [],
        button: ['确认'],
        events: {},
        params: {}
      },
      meetingMsg:{
        title:"提示",
        content:[],
        style:[],
        button:{},
        events:{},
        params:{}
      },
      isRecord:false,
      isComplete:false,
      showMeetingTip:false,
      showH5:true,
      config:{
        stepText: ["","本场会议的讲题：<span style='font-weight:700'>《$$》</span>，欢迎$$教授开场致辞","跟专家一起学习《$$》吧", "现在进入讨论环节，欢迎大家积极提问！", "本场会议已结束，感谢您的参与。"],
        events: {
          login:"dealWithLogin",
          control:"dealWithControl",
          online: "dealWithOnline",
          offline: "dealWithOffline",
          chat: "dealWithChat",
          video: "dealWithVideo",
          error: "dealWithError",
          shield: "dealWithShield",
        },
        stepKeys:{}
      },
      stream:null,
      liveTime:{
        timer:null,
        isRun:false,
        spaceTime:3000, // 至少录制毫秒数
        recordTime:0, // 点击录制的时间
        startTime:0, // 开始初始化的时间
        currTime:0, // 当前录制时间数
        maxCount:120 // 最大录制秒数
      },
      isCanPlay:false,// 是否获取了权限
      isOverTime:false,//会议是否超时(专家离线一小时)
      playId:0,
      showPlayer:true,
      connectTime:true,
      videoURL:"",
      videoList:[],
      tempChatList:[], // 临时会议
      liveHeight:0,
      isAdmin:false,
    }
  },
  components:{
    MyPlayer,
    H5Player,
    PhasePlayer,
    MeetingInfoItem,
    MeetingTip,
    Banner,
  },
  created(){
    this.meetingInfo.meeting_number = this.$route.query.meeting_number;
    this.meetingInfo.role = Number(this.$route.query.role || 0);
    // this.isAdmin = Number(this.$route.query.isAdmin) || 0;
  },
  mounted(){
    this.initMeetingTip('welcome')
    this.initMeeting()
    this.$nextTick(()=>{
      this.liveHeight = document.getElementById("live").clientHeight
    })
  },
  watch:{
    "$store.state.common.isConnect":function(){
      let status = this.connectTime++ == 0 ? true : this.$store.state.common.isConnect
      if(!status){
        this.showMeetingTip = true
        this.initMeetingTip('connect')
        return;
      }else{
        this.showMeetingTip = false
      }
    }
  },
  computed:{
    //是否正在开始正式会议
    getIsLive(){
      //会议审核失败
      if(this.meetingInfo?.meet_info?.status == 2&&this.meetingInfo?.meet_info?.audit_status == 30) return false;
      //是测试会议时，管理员不弹框，其他观众需要弹框
      if(this.meetingInfo.phase>0 && this.meetingInfo.is_test==1){
        return this.isAdmin && !this.isOverTime
      }
      //正式会议若超时都要弹框
      return (this.meetingInfo.phase>0 && this.meetingInfo.is_test==0) && !this.isOverTime
    },
    components(){
      let path = this.meetingInfo.tab == 0 ? 'live-chat' : 'live-info'
      return defineAsyncComponent( ()=>import(`@/components/page/live/components/${path}`))
    }
  },
  
  methods: {
    /** 初始化相关 */
    initLiveTime(){
      this.liveTime.isRun = true
      if(!this.liveTime.startTime){
        this.liveTime.startTime = this.$tools.getTime()
      }
      if(this.liveTime.timer){
        clearTimeout(this.liveTime.timer)
        this.liveTime.timer = null
      }
      if(this.isRecord){
        this.liveTime.currTime += 1
        if(this.liveTime.currTime > this.liveTime.maxCount){
          this.toggleRecored()
        }
      }
      if(!this.$tools.isGuest(this.meetingInfo)){
        this.initLoadedMeetingTip()
      }else{
        this.playVideo()
      }
      this.timer = setTimeout(()=>{
        this.initLiveTime()
      },1000)
    },
    initMeeting(){
      this.initWebsocket()
    },
    initWebsocket(){
      websocket.initWebsocket(
        this.meetingInfo.meeting_number,
        this.dealWithCallback,
        {
          openid:this.$tools.getOpenid(),
          role:this.meetingInfo.role
        }
      )
    },   
    initRecorder(){
      recorder.init((stream)=>{
        this.stream = stream
        if(this.meetingInfo.phase !== 2){
          this.initStream()
        }
      })
    },
    initStream(){
      this.showH5 = true
      this.changeURL(this.stream)
    },
    initVideo(){
      try{
        this.$refs.player && this.$refs.player.pause();
      }catch(err){}
    },
    initShareInfo(){
      weixin.shareMessage({
        url:location.href,
        title:`【百展讲堂】${this.meetingInfo.class_info.title}`,
        description:`互动医生：${ this.meetingInfo.meet_info.doctor_name}\n${this.meetingInfo.meet_info.start_time}`,
        logo:this.$config.logo
      })
    },
    // 初始化会议提示
    initMeetBeforeTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg,
        events:[
          this.selectMeetingStatus,
          this.selectMeetingStatus,
        ],
        params:[1,0]
      }
    },
    // 初始化会议开始提示
    initMeetStartTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg
      }
    },
    // 初始化视频提示
    initMeetVideoTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg,
        events:[
          this.goToNextPhase
        ]
      }
    },
    // 初始化讨论提示
    initDiscussTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg,
        events:[
          this.goToNextPhase
        ]
      }
    },
    // 初始化结束提示
    initEndMeetingTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg,
        events:[
          this.goToNextPhase
        ]
      }
    },
    // 初始化讨论即将结束提示
    initDiscussEndTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg
      }
    },
    // 初始化欢饮
    initCommonTip(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg,
        events:[this.defaultPlay]
      }
    },
    initVideoList(){
      localStorage.removeItem("alreadyPlayVideo")
      let alreadyPlayVideo = this.$tools.getStorage("alreadyPlayVideo") || []
      let videoList = [].concat(this.meetingInfo.chat_info);
      console.log(videoList)
      this.videoList =  videoList.filter(chat=>{
        return (!alreadyPlayVideo.includes(chat.id) && chat.content_type == 1)
      })
    },
    // 初始化链接提示
    initConnectTips(meetingMsg){
      this.meetingMsg = {
        ...meetingMsg,
        events:[
          this.reloadPage
        ]
      }
    },
    initMeetingTip(popupKey){
      popupKey = popupKey || this.meetingInfo.phase
      if(this.config.stepKeys[popupKey] || this.showMeetingTip) return;
      this.config.stepKeys[popupKey] = true
      console.log(this.$config.popups.phase[popupKey])
      let meetingMsg = {
        title:"提示",
        ...this.$config.popups.phase[popupKey],
      }
      switch(popupKey){
        case 0:
          this.initMeetBeforeTip(meetingMsg)
          break;
        case 1:
          this.initMeetStartTip(meetingMsg)
          break;
        case 2:
          this.initMeetVideoTip(meetingMsg)
          break;
        case 3:
          this.initDiscussTip(meetingMsg)
          break;
        case "endMeeting":
          this.initEndMeetingTip(meetingMsg)
          break;
        case "discuss":
          this.initDiscussEndTip(meetingMsg)
          break;
        case "connect":
          this.initConnectTips(meetingMsg)
          break;
        case "welcome":
          this.initCommonTip(meetingMsg)
          break;
      }
      this.showMeetingTip = true;
      console.log(this.showMeetingTip)
    },
    
    initLoadedMeetingTip(){
      let chatCount,phaseTime
      if(this.showMeetingTip) return;
      switch(this.meetingInfo.phase){
        case 0:
          this.initMeetingTip();
          break;
        case 1:
          chatCount = this.$tools.calcChatCount(this.meetingInfo)
          if(chatCount >= this.$config.meeting.start){
            this.initMeetingTip(2);
          }else{
            this.initMeetingTip(1);
          }
          break;
        case 2:
          phaseTime = this.meetingInfo.control[1].create_time
          if(this.$tools.getTime() - this.$tools.getTime(phaseTime) >= this.meetingInfo.class_info.play_video_time*1000){
            this.initMeetingTip(3);
          }
          break;
        case 3:
          chatCount = this.$tools.calcChatCount(this.meetingInfo)
          if(chatCount >= this.meetingInfo.meet_info.discuss_time){
            this.initMeetingTip('endMeeting');
            return;
          }
          if(chatCount < this.meetingInfo.meet_info.discuss_time && chatCount >= this.meetingInfo.meet_info.discuss_time - 60 ){
            this.initMeetingTip('discuss');
          }
          break;
      }
    },
    
    /** 初始化相关 */

    /** 操作相关 */
    closeQuestion(){
      try{
        this.$refs.coursePage.closeQuestion()
      }catch(err){
        console.log(err)
      }
    },
    getLiveStyle(){
      if(!this.liveHeight) return ""
      return `height:${this.liveHeight}px`
    },
    getMeetStatusTips(){
      //正式会议
      // if(this.meetingInfo.phase > 0 && this.meetingInfo.is_test == 0){
      //   if(this.meetingInfo?.meet_info.audit_status== 30) return "会议已结束";
      //   if(this.isOverTime) return "会议已结束";
      // }
      // //测试会议(管理员不弹提示框)
      // if(this.meetingInfo.phase > 0 && this.meetingInfo.is_test == 1){
      //   return "会议尚未开始";
      // }
      console.log(this.isOverTime)
      console.log(this.meetingInfo.phase)
      console.log(this.meetingInfo.is_test)
      console.log(this.meetingInfo.audit_status)
      return (this.meetingInfo.phase > 0 && this.meetingInfo.is_test == 0) && (this.meetingInfo?.meet_info.audit_status == 30||this.isOverTime)?"会议已结束":"会议尚未开始"
    },
    selectMeetingStatus(status){
      this.meetingInfo.is_test = status
      this.goToNextPhase()
      // this.$nextTick(()=>{
      //   this.initMeetingTip(1);
      // })
    },
    changeBanner(tab){
      this.meetingInfo.tab = tab
      // this.$refs.player.setMuted(true)
    },
    quitMeet(){
      //从签到页面进入的需要退回两个页面
      if(this.$route.query.from&&this.$route.query.from=="sign"){
        if(window.history.length > 2){
          this.$router.go(-2);
        }else{
          weixin.wx.closeWindow();
        }
      }else{
        if(window.history.length > 1){
          this.$router.back();
        }else{
          weixin.wx.closeWindow();
        }
      }
    },
    doSelect(index){
      this.showMeetingTip = false;
      this.isCanPlay = true;
      if(this.meetingMsg.events){
        if(this.meetingMsg.params && this.meetingMsg.params[index] !== undefined){
          this.meetingMsg.events[index](this.meetingMsg.params[index])
        }else{
          this.meetingMsg.events[index]()
        }
      }
    },
    playVideo(){
      if(document.hidden) return;
      if(!this.isCanPlay || this.videoList.length == 0 || this.playId) return
      let chat = this.videoList.shift();
      this.playId = chat.id;
      this.changeURL(chat.content,chat.time)
    },
    playCourseware(time=0){
      this.videoList.push({content:this.meetingInfo.class_info.play_video_url,id:99999,time})
      this.playVideo()
    },
    initCoursewareTime(){
      this.videoList.push({
        content:this.meetingInfo.class_info.play_video_url,
        id:99999,
        time:Math.floor((this.$tools.getTime(this.meetingInfo.server_time) - this.$tools.getTime(this.meetingInfo.control[1].create_time))/1000)
      })
    },
    callPlay(){
      // return;
      try{
        this.$refs.player.play()
      }catch(err){}
    },
    playEnd(){
      let alreadyPlayVideo = this.$tools.getStorage("alreadyPlayVideo") || []
      alreadyPlayVideo.push(this.playId)
      this.$tools.setStorage("alreadyPlayVideo",alreadyPlayVideo)
      this.playId = 0
      if(this.videoList.length == 0 &&  this.meetingInfo.phase ==4){
        this.sendEndMeeting()
      }
    },
    loaded(){
      this.$refs.player.play(true)
    },
    /** 操作相关 */

    /** 协议相关 */
    reloadPage(){
      window.location.reload()
    },
    goToNextPhase(){
      this.sendMessage({
        type:'control',
        data:{
          is_test:this.meetingInfo.is_test,
          phase:this.meetingInfo.phase + 1
        }
      })
    },
    sendChat(data){
      let msg = {
        type:'chat',
        data
      }
      this.sendMessage(msg)
    },
    sendMessage(data){
      websocket.sendMessage(data)
    },
    dealWithCallback(data) {
      this[this.config.events[data.type]](data.data)
    },
    dealWithLogin(data){
      this.meetingInfo = {...this.meetingInfo,...data}
      //专家离线一小时则超时
      // this.isOverTime = this.meetingInfo.offline_time&&(this.$tools.getTime(this.meetingInfo.offline_time) + 60 * 60 * 1000 < this.$tools.getTime());
      this.isOverTime = this.meetingInfo.control.length != 4 && this.meetingInfo.phase ==4
      this.isAdmin = this.meetingInfo.sale_openid==this.$tools.getOpenid()
      console.log(this.isAdmin)
      // console.log(this.$tools.getOpenid(),"=====",this.meetingInfo.sale_openid,"=====",this.isAdmin)
      this.isComplete = true;
      // console.log(this.isOverTime)
      document.getElementsByTagName("title")[0].innerHTML = this.meetingInfo.meet_info.name
      if(!this.$tools.isGuest(this.meetingInfo) && !this.stream){
        this.initRecorder()
      }
      if(this.meetingInfo.phase <= 2){
        this.showPlayer = false
      }
      if(this.meetingInfo.phase ==2){
        this.initCoursewareTime()
      }
      this.initShareInfo()
      if(!this.$tools.isGuest(this.meetingInfo))   this.initLoadedMeetingTip()
      else {
        // this.initVideoList()
      }
      if(!this.liveTime.isRun) this.initLiveTime()
      setTimeout(()=>{this.sendMeetingName()},100)
    },
    initMeetingInfo(){
      this.meetingInfo.is_test = false;
      this.meetingInfo.chat_info = []
      this.meetingInfo.control = []
      this.meetingInfo.isPlaying = false
      this.meetingInfo.phase = 0
      for(let key in this.config.stepKeys){
        this.config.stepKeys[key] = false
      }
    },
    dealWithControl(data){
      this.meetingInfo.phase = data.phase;
      this.meetingInfo.is_test = data.is_test;
      if(this.meetingInfo.phase == 0){
        this.initMeetingInfo()
        // this.sendMeetingName()
      }else{
        this.meetingInfo.control[data.phase - 1] = data
      }
      if(this.meetingInfo.phase == 2){
        this.playCourseware()
      }
      if(!this.$tools.isGuest(this.meetingInfo) && data.phase == 3){
        this.initStream()
      }
      // if (this.meetingInfo.phase == 1) {
      //   this.sendSystemMessage(this.$config.popups.startMeet, "#008f3d");
      //   return;
      // }
      let text = this.config.stepText[this.meetingInfo.phase]
      if(!text) return;
      if(this.meetingInfo.phase < 4){
        text = this.$tools.getTipInfo(text,[this.meetingInfo.class_info.title,this.meetingInfo.meet_info.doctor_name])
        this.sendSystemMessage(text,this.$config.color);
      }else{
        if(!(this.videoList.length  || this.playId)){
          this.sendEndMeeting()
        }
      }
    },
    sendEndMeeting(){
      let text = this.config.stepText[this.meetingInfo.phase]
      text = this.$tools.getTipInfo(text,[this.meetingInfo.class_info.title,this.meetingInfo.meet_info.doctor_name])
      this.sendSystemMessage(text,this.$config.color);
    },
    dealWithOnline(data){
      let text = "",
      color = undefined;
      if (data.openid == this.meetingInfo.openid) {
        color = "#008f3d";
        text = `${data.name}${this.$config.popups.academic}`;
        if(this.$tools.isGuest(this.meetingInfo)){
          this.initStream()
        }
      } else text = data.name;
      if (this.meetingInfo.phase >= 0  && data.openid == this.meetingInfo.openid ) {
        this.sendSystemMessage(`${text}${this.$config.popups.infloor}`, color);
      }
      if(this.meetingInfo.is_test){

      }
      let minPerson = this.meetingInfo.is_test || [0,4].includes(this.meetingInfo.phase) ? 2 : 3
      this.meetingInfo.count = data.online_count > minPerson ? data.online_count : minPerson;
    },
    dealWithOffline(data){
      let minPerson = this.meetingInfo.is_test || [0,4].includes(this.meetingInfo.phase) ? 2 : 3
      this.meetingInfo.count = data.online_count > minPerson ? data.online_count : minPerson;
    },
    dealWithChat(data){
      // console.log("dealWithChat========",data)
      
      this.meetingInfo.chat_info.push(data)
      // if(this.meetingInfo.phase < 4){
      //   this.meetingInfo.chat_info.push(data)
      // }else{
      //   this.tempChatList.push(data)
      // }
      // if(this.meetingInfo.word_question.includes(data.content)){
      //   this.sendSystemMessage("本互动不提供涉及药品或诊疗相关的任何专业咨询，如对于药品或诊疗相关的任何问题须咨询专业医生。","gray")
      // }
      this.$nextTick(()=>{
        this.$refs.coursePage.initScroll();
      })
      if(data.content_type == 1){
        this.enterVideoList(data)
      }
    },
    
    dealWithVideo(data){
      // let isMiddlePlay = false
      // if (data.action == "play") {
      //   this.meetingInfo.isPlaying = true;
      //   if(this.meetingInfo.control[1]){
      //     isMiddlePlay = this.$tools.getTime(this.meetingInfo.control[1].create_time) + 60 * 1000 < Date.now()
      //   }
      //   this.validURL(data.url,0, isMiddlePlay);
      // } else {
      //   this.showH5 = true
      //   this.meetingInfo.isPlaying = false;
      //   this.initVideo();
      //   this.$refs.player.setCover("")
      // }
    },
    dealWithError(data){
      this.$tips.error({ text: data.msg });
    },
    dealWithShield(data){
      this.$tips.error({ text: data.msg });
    },
    sendMeetingName(){
      let meetingName = this.config.stepText[0]
      meetingName = this.$tools.getTipInfo(this.config.stepText[0],[this.meetingInfo.class_info.title,this.meetingInfo.meet_info.doctor_name])
      this.sendSystemMessage(meetingName,this.$config.color);
    },
    sendSystemMessage(text, color =this.$config.color) {
      if (!text) return;
      let msg = {
        name: "系统",
        content: text,
        color,
      };
      this.meetingInfo.chat_info.push(msg);
      this.$nextTick(()=>{
        this.$refs.coursePage.initScroll();
      })
    },
    /** 协议相关 */

    
    /** 音频相关 */
    async toggleRecored(){
      if(!this.stream) return;
      if(this.execRecord || this.$tools.getTime() -  this.liveTime.recordTime < this.liveTime.spaceTime) return;
      this.execRecord = true;
      let res;
      if(!this.isRecord){
        this.liveTime.recordTime = Date.now()
        this.isRecord = true;
        this.$refs.player.setCoverStatus(false)
        res = await recorder.startRecord(this.meetingInfo.meeting_number)
        if(res.status !== 200){
          this.isRecord = false;
          this.$tips.error({text:"录制失败，请重新录制"})
        }
      }else{
        this.$refs.player.setCoverStatus(true)
        this.isRecord = false
        let voiceCount = this.liveTime.currTime
        this.liveTime.currTime = 0
        res = await recorder.stopRecord(voiceCount)
        console.log(res)
        if(res.status !== 200){
          this.isRecord = false;
          this.$tips.error({text:"录制失败，请重新录制"})
        }else{
          this.sendChat(this.$tools.getChatContent(res.data.url,this.meetingInfo.name,1,voiceCount))
        }
      }
      this.execRecord = false;
    },
    enterVideoList(chat){
      let ids = this.videoList.map(chat=>chat.id)
      if(!ids.includes(chat.id)) this.videoList.push(chat)
    },
    changeURL(url,chatTime) {
      url = url || this.videoURL
      this.$nextTick(()=>{
        this.$refs.player.switch(url,chatTime)
      })
    },
    validURL(url, count,isMiddlePlay = false) {
      this.$axios.get(url).then((res) => {
        if (this.meetingInfo.phase > 2) {
          return;
        }
        if (count < this.$config.maxPlayTimes && !isMiddlePlay) {
          if (count % this.$config.spaceTime == 0) {
            let index = Math.floor(count / this.$config.spaceTime);
            this.$config.popups.video[index] && this.sendSystemMessage(this.$config.popups.video[index]);
            if (count == 0)
              setTimeout(() => {
                this.sendSystemMessage(this.$config.popups.startVideo);
              }, 7000);
            }
          setTimeout(() => {
            this.validURL(url,count+1);
          }, 1000);
        } else {
          this.sendSystemMessage(this.$config.popups.endVideo, "#008f3d");
          this.videoURL = url;
          this.showH5 = false;
          setTimeout(()=>{
            this.changeURL();
            this.$nextTick(()=>{
              this.$refs.player.setCover(this.meetingInfo.class_info.cover)
            })
          },1000)
          // this.sendSystemMessage(`课件开始播放`)
        }
      }).catch(err=>{
        count = 0;
        setTimeout(() => {
          this.validURL(url,count);
        }, 1000);
      });
    },
    /** 音频相关 */
    

    
    /** 工具相关 */
    defaultPlay(){
      this.$refs.player.playDefault()
    }, 
    /** 工具相关 */
    
    /** 样式相关 */
    /** 样式相关 */
  },
  beforeUnmount() {
    websocket.close();
  },
}
</script>
<style>
@import "./live.scss";
</style>