<template>
  <div class="meeting-info">
    <div class="meeting-block">
      <span>主持专家：</span>
      <span class="global_primary_text_color">
        在线
      </span>
    </div>
    <div class="meeting-block" v-if="!meetingInfo.is_test && meetingInfo.phase > 0">
      <span>会议助理：</span>
      <span class="global_primary_text_color">
        在线
      </span>
    </div>
    <div class="meeting-block" v-if="$tools.isGuest(meetingInfo)">
      <span>参会人数： </span>
      <span class="global_primary_text_color">{{ meetingInfo.count || 0 }}</span>
    </div>
    <!-- <div class="meeting-block" v-if="meetingInfo.phase != 2">
      <span>{{getPhaseText()}}已进行： </span>
      <span class="global_primary_text_color">
        {{meetingCountTime}}
      </span>
    </div> -->
  </div>
</template>
<script>
export default {
  name:"meetingInfo",
  props:{
    meetingInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  computed:{
    meetingCountTime(){
      try{
            if (!this.meetingInfo.control || this.meetingInfo.control.length == 0) return "未开始";
            if (this.meetingInfo.phase >= 4) return "已结束";
            let phase = this.meetingInfo.control.length < 2 ? 1 : 3
            let countTime = this.$tools.calcChatCount(this.meetingInfo,phase) || 0
            return this.$tools.getShowTime(countTime*1000)
          }catch(err){
            console.log(err)
            return ""
          }
      },
    },
  methods:{
    getPhaseText(){
      return this.meetingInfo.phase > 2 ? "讨论" : "开场"
    }
  },
    

}
</script>
<style scoped>
.meeting-info{
  width: 100%;
  height: 3.33vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
  display: flex;
  justify-content: space-between;
  font-size: 3.33vw;
  text-align: center;
  color: #666;
  font-weight: 400;
  padding: 0 4vw;
}
.meeting-block{
  flex-grow: 1;
  height: 100%;
  /* font-size:4vw;; */
  color: #5c5c5c;
  font-size: 3.2vw;
}
.meeting-block:nth-child(2){
  text-align: center;
}
.meeting-block:last-child{
  text-align: right;
}
.meeting-block:first-child{
  text-align: left;
}
</style>